import { NATIVE_TOKEN_ADDRESS } from './constants';
import {
  bitcoinTestnet,
  sepolia,
  polygonMumbai,
  dotTestnet,
  backspinEthereum,
  backspinArbitrum,
  arbitrumSepolia,
} from '../chains';
import { type Token, type ChainflipToken, flip$, btc$, dot$, usdt$, eth$, usdc$, matic$ } from '.';

export const tbtc$ = {
  chain: bitcoinTestnet,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Test Bitcoin',
  symbol: 'tBTC',
  decimals: 8,
  chainflipId: 'Btc',
} as const satisfies ChainflipToken;

export const pdot$ = {
  chain: dotTestnet,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Test Polkadot',
  symbol: 'pDOT',
  decimals: 10,
  chainflipId: 'Dot',
} as const satisfies ChainflipToken;

export const seth$ = {
  chain: sepolia,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Sepolia Ether',
  symbol: 'sETH',
  decimals: 18,
  chainflipId: 'Eth',
} as const satisfies ChainflipToken;

export const sflip$ = {
  chain: sepolia,
  address: process.env.NEXT_PUBLIC_FLIP_CONTRACT_ADDRESS as string,
  name: 'Testnet FLIP',
  symbol: 'sFLIP',
  canonicalSymbol: 'FLIP',
  decimals: 18,
  chainflipId: 'Flip',
} as const satisfies ChainflipToken;

export const susdc$ = {
  chain: sepolia,
  address: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
  name: 'Sepolia USDC',
  symbol: 'sUSDC',
  canonicalSymbol: 'USDC',
  decimals: 6,
  chainflipId: 'Usdc',
} as const satisfies ChainflipToken;

export const susdt$ = {
  chain: sepolia,
  address: '0x27CEA6Eb8a21Aae05Eb29C91c5CA10592892F584',
  name: 'Sepolia USDT',
  symbol: 'sUSDT',
  decimals: 6,
  chainflipId: 'Usdt',
} as const satisfies ChainflipToken;

export const beth$ = {
  chain: backspinEthereum,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Backspin Ether',
  symbol: 'bETH',
  decimals: 18,
  chainflipId: 'Eth',
} as const satisfies ChainflipToken;

export const bflip$ = {
  chain: backspinEthereum,
  address: '0x10C6E9530F1C1AF873a391030a1D9E8ed0630D26',
  name: 'Backspin Flip',
  symbol: 'bFLIP',
  decimals: 18,
  chainflipId: 'Flip',
} as const satisfies ChainflipToken;

export const busdc$ = {
  chain: backspinEthereum,
  address: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
  name: 'Backspin Usdc',
  symbol: 'bUSDC',
  decimals: 6,
  chainflipId: 'Usdc',
} as const satisfies ChainflipToken;

export const busdt$ = {
  chain: backspinEthereum,
  address: '0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82',
  name: 'Backspin USDT',
  symbol: 'bUSDT',
  decimals: 6,
  chainflipId: 'Usdt',
} as const satisfies ChainflipToken;

export const mumbaiMatic$ = {
  chain: polygonMumbai,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Mumbai Polygon',
  symbol: 'MATIC',
  decimals: 18,
} as const satisfies Token;

export const barbusdc$ = {
  chain: backspinArbitrum,
  address: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
  name: 'Backspin arbUSDC',
  symbol: 'bUSDC',
  decimals: 6,
  chainflipId: 'ArbUsdc',
} as const satisfies ChainflipToken;

export const barbeth$ = {
  chain: backspinArbitrum,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Backspin arbETH',
  symbol: 'bETH',
  decimals: 18,
  chainflipId: 'ArbEth',
} as const satisfies ChainflipToken;

export const sarbusdc$ = {
  chain: arbitrumSepolia,
  address: '0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d',
  name: 'Sepolia arbUSDC',
  symbol: 'sUSDC',
  decimals: 6,
  chainflipId: 'ArbUsdc',
} as const satisfies ChainflipToken;

export const sarbeth$ = {
  chain: arbitrumSepolia,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Sepolia arbETH',
  symbol: 'sETH',
  decimals: 18,
  chainflipId: 'ArbEth',
} as const satisfies ChainflipToken;

export const getTokenKey = (token: Token | { address: string; chainId: string }) => {
  const chainId = 'chain' in token ? token.chain.id : token.chainId;
  return `${chainId}-${token.address}`.toLowerCase();
};

export const getMainnetTokenEquivalent = (token: Token) => {
  // load prices of mainnet token to get realistic prices for testnet tokens
  switch (getTokenKey(token)) {
    case getTokenKey(sflip$):
      return flip$;
    case getTokenKey(tbtc$):
      return btc$;
    case getTokenKey(pdot$):
      return dot$;
    case getTokenKey(susdt$):
      return usdt$;
    case getTokenKey(seth$):
      return eth$;
    case getTokenKey(susdc$):
      return usdc$;
    case getTokenKey(mumbaiMatic$):
      return matic$;
    case getTokenKey(bflip$):
      return flip$;
    case getTokenKey(busdc$):
      return usdc$;
    case getTokenKey(busdt$):
      return usdt$;
    case getTokenKey(beth$):
      return eth$;
    case getTokenKey(sarbeth$):
      return eth$;
    case getTokenKey(sarbusdc$):
      return usdc$;
    case getTokenKey(barbeth$):
      return eth$;
    case getTokenKey(barbusdc$):
      return usdc$;
    default:
      return token;
  }
};

export default [
  tbtc$,
  beth$,
  seth$,
  bflip$,
  sflip$,
  busdc$,
  susdc$,
  pdot$,
  busdt$,
  susdt$,
  mumbaiMatic$,
  barbusdc$,
  sarbusdc$,
  sarbeth$,
  barbeth$,
];
