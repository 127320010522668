import { NATIVE_TOKEN_ADDRESS } from './constants';
import { arbitrum, bitcoin, ethereum, polkadot, polygon } from '../chains';
import { type Token, type ChainflipToken } from '.';

export const btc$ = {
  chain: bitcoin,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Bitcoin',
  symbol: 'BTC',
  decimals: 8,
  chainflipId: 'Btc',
} as const satisfies ChainflipToken;

export const dot$ = {
  chain: polkadot,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Polkadot',
  symbol: 'DOT',
  decimals: 10,
  chainflipId: 'Dot',
} as const satisfies ChainflipToken;

export const eth$ = {
  chain: ethereum,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
  chainflipId: 'Eth',
} as const satisfies ChainflipToken;

export const flip$ = {
  chain: ethereum,
  address: '0x826180541412D574cf1336d22c0C0a287822678A',
  name: 'Chainflip',
  symbol: 'FLIP',
  decimals: 18,
  chainflipId: 'Flip',
} as const satisfies ChainflipToken;

export const usdc$ = {
  chain: ethereum,
  address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  name: 'USDC',
  symbol: 'USDC',
  decimals: 6,
  chainflipId: 'Usdc',
} as const satisfies ChainflipToken;

export const usdt$ = {
  chain: ethereum,
  address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  name: 'USDT',
  symbol: 'USDT',
  decimals: 6,
  chainflipId: 'Usdt',
} as const satisfies ChainflipToken;

export const matic$ = {
  chain: polygon,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Polygon',
  symbol: 'MATIC',
  decimals: 18,
} as const satisfies Token;

export const arbusdc$ = {
  chain: arbitrum,
  address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  name: 'Arbitrum USDC',
  symbol: 'USDC',
  decimals: 6,
  chainflipId: 'ArbUsdc',
} as const satisfies ChainflipToken;

export const arbeth$ = {
  chain: arbitrum,
  address: NATIVE_TOKEN_ADDRESS,
  name: 'Arbitrum Ether',
  symbol: 'ETH',
  decimals: 18,
  chainflipId: 'ArbEth',
} as const satisfies ChainflipToken;

export default [btc$, eth$, dot$, flip$, usdc$, usdt$, matic$, arbusdc$, arbeth$];
